import React from 'react';

const TagServicesWidget = ({tag}) => {
    const {name, services} = tag;
    return (
        <div className="widget widget_categories">
            <h3 className="widget-title">What we do with {name}</h3>

            <p style={{marginTop: "20px", fontSize: "smaller"}}>
                {services}
            </p>
        </div>
    );
};

export default TagServicesWidget;
